// import gc_logo from "../assets/gc_logo.png";
import gc_logo from "../assets/logo.png";
import gconveyors from "../assets/gconveyors.png";
import bg_img from "../assets/bg_img.jpeg";
import Factory_img from "../assets/Factory_img.png";
import factory_gray from "../assets/factory_gray.jpg";
import chain_mix from "../assets/chain_mix.jpg";
// import General_Chains from "../assets/General_Chains.jpg";
import ProductRange from "../assets/ProductRange.jpg";

import quality_assurance from "../assets/quality_assurance.png";
import customer_satisfaction from "../assets/customer_satisfaction.png";
import mutual_growth from "../assets/mutual_growth.png";
import teamwork from "../assets/teamwork.png";
import respect_all from "../assets/respect_all.png";

import agriculture from "../assets/agriculture.png";
import sugarcane from "../assets/sugarcane.png";
import escalator from "../assets/escalator.png";
import otherindustry from "../assets/otherindustry.png";
import power_plant from "../assets/power_plant.png";
import tobacco from "../assets/tobacco.png";
import Mining from "../assets/Mining.png";
import Fertilizer_Plants from "../assets/Fertilizer_Plants.png";
import textile from "../assets/textile.png";
import Steel_Plants from "../assets/Steel_Plants.png";
import Cement_Plants from "../assets/Cement_Plants.png";

import chain_img_bg from "../assets/chain_img_bg.jpeg";
import global from "../assets/global.png";
import india_location from "../assets/india_location.png";
import Industries_Serving from "../assets/Industries_Serving.png";
import Team_Members from "../assets/Team_Members.png";
import Happy_Customers from "../assets/Happy_Customers.png";
import Manufacturing_Capacity from "../assets/Manufacturing_Capacity.png";
import Manufacturing_Unitof from "../assets/Manufacturing_Unitof.png";
import experience from "../assets/experience.png";
import RollerChains_img from "../assets/RollerChains_img.png";
import chain_img from "../assets/About_Img_2.jpg";
import Chainimg from "../assets/Chainimg.jpeg";
import RollerChain1 from "../assets/RollerChain1.png";
import RollerChain2 from "../assets/RollerChain2.png";
import RollerChain3 from "../assets/RollerChain3.png";
import RollerChain4 from "../assets/RollerChain4.png";
import home_slider_img1 from "../assets/home_slider_img1.jpg";
import home_slider_img2 from "../assets/home_slider_img2.webp";
import home_slider_img3 from "../assets/home_slider_img3.jpg";

import cat_roller from "../assets/cat_roller.png";
import cat_agriculture from "../assets/cat_agriculture.png";
import cat_leaf from "../assets/GC_Catagories/cat_leaf.jpg";
import cat_bush from "../assets/GC_Catagories/cat_bush.jpg";
import cat_extended from "../assets/cat_extended.png";
import core_purpose_head from "../assets/core_purpose_head_img.png";
import core_img1 from "../assets/core_img1.png";
import core_img2 from "../assets/core_img2.png";
import core_img3 from "../assets/core_img3.png";
import core_img4 from "../assets/core_img4.png";
import core_img5 from "../assets/core_img5.png";
import simplexRollerChain from "../assets/simplexRollerChain.png";
import duplexRollerChain from "../assets/duplexRollerChain.png";
import triplexRollerChain from "../assets/triplexRollerChain.png";
import LLLeafChain from "../assets/LLLeafChain.png";
import BLLeafChain from "../assets/BLLeafChain.png";
import ALLeafChain from "../assets/ALLeafChain.png";
import BushChain from "../assets/BushChain.png";
import ExtendedPitchChain from "../assets/ExtendedPitchChain.png";
import FeederChain from "../assets/FeederChain.png";
import ElevatorChain from "../assets/ElevatorChain.png";

import Other1 from "../assets/Other1.png";
import Other2 from "../assets/Other2.png";
import Other3 from "../assets/Other3.png";
import Other4 from "../assets/Other4.png";
import Other5 from "../assets/Other5.png";
import Other6 from "../assets/Other6.png";
import Other7 from "../assets/Other7.png";
import Other8 from "../assets/Other8.png";
import Other9 from "../assets/Other9.png";
import Other10 from "../assets/Other10.png";
import Other11 from "../assets/Other11.png";
import Other12 from "../assets/Other12.png";
import Other13 from "../assets/Other13.png";
import Other14 from "../assets/Other14.png";
import Other15 from "../assets/Other15.png";
import Other16 from "../assets/Other16.png";
import Other17 from "../assets/Other17.png";
import About_head from "../assets/About_head.png";
import Certificate1 from "../assets/Certificate1.png";
import Certificate2 from "../assets/Certificate2.png";

import RollerChainSimplex from "../assets/RollerChainSimplex.jpg";
import RollerChainSimplexGraph from "../assets/RollerChainSimplexGraph.jpg";
import RollerChainDuplexImage from "../assets/RollerChainDuplexImage.jpg";
import RollerChainDuplexGraph from "../assets/RollerChainDuplexGraph.jpg";
import RollerChainTriplex from "../assets/RollerChainTriplex.jpg";
import RollerChainTriplexGraph from "../assets/RollerChainTriplexGraph.jpg";
import LeafChain from "../assets/LeafChain.jpg";
import LeafChainGraph from "../assets/LeafChainGraph.jpg";
import ExtendedPitchChainImage from "../assets/ExtendedPitchChainImage.jpg";
import ExtendedPitchChainGraph from "../assets/ExtendedPitchChainGraph.jpg";
import BushChainImage from "../assets/BushChainImage.jpg";
import BushChainGraph from "../assets/BushChainGraph.jpg";
import AgricultureFeederChain from "../assets/AgricultureFeederChain.jpg";
import AgricultureFeederChainGraph from "../assets/AgricultureFeederChainGraph.jpg";
import AgricultureElevatorChain from "../assets/AgricultureElevatorChain.jpg";
import AgricultureElevatorChainGraph from "../assets/AgricultureElevatorChainGraph.jpg";

import ind1 from "../assets/GC_Industries/ind1.jpg";
import ind2 from "../assets/GC_Industries/ind2.jpg";
import ind3 from "../assets/GC_Industries/ind3.jpg";
import ind4 from "../assets/GC_Industries/ind4.jpg";
import ind5 from "../assets/GC_Industries/ind5.jpg";
import ind6 from "../assets/GC_Industries/ind6.jpg";
import ind7 from "../assets/GC_Industries/ind7.jpg";
import ind8 from "../assets/GC_Industries/ind8.jpg";
import ind9 from "../assets/GC_Industries/ind9.jpg";
import ind10 from "../assets/GC_Industries/ind10.jpg";
import ind11 from "../assets/GC_Industries/ind11.jpg";
import ind12 from "../assets/GC_Industries/ind12.jpg";
import catalogue from "../assets/catalogue.pdf";

export default {
  catalogue,
  factory_gray,
  Factory_img,
  ProductRange,
  chain_mix,
  About_head,
  Certificate1,
  Certificate2,
  bg_img,
  gc_logo,
  gconveyors,
  quality_assurance,
  customer_satisfaction,
  mutual_growth,
  teamwork,
  respect_all,
  agriculture,
  sugarcane,
  escalator,
  otherindustry,
  power_plant,
  tobacco,
  Mining,
  Fertilizer_Plants,
  textile,
  Steel_Plants,
  Cement_Plants,
  chain_img_bg,
  global,
  india_location,
  Industries_Serving,
  Team_Members,
  Happy_Customers,
  Manufacturing_Capacity,
  Manufacturing_Unitof,
  experience,
  RollerChains_img,
  Chainimg,
  chain_img,
  RollerChain1,
  RollerChain2,
  RollerChain3,
  RollerChain4,
  cat_roller,
  cat_agriculture,
  cat_leaf,
  cat_bush,
  cat_extended,
  core_purpose_head,
  core_img1,
  core_img2,
  core_img3,
  core_img4,
  core_img5,
  home_slider_img1,
  home_slider_img2,
  home_slider_img3,
  simplexRollerChain,
  duplexRollerChain,
  triplexRollerChain,
  LLLeafChain,
  BLLeafChain,
  ALLeafChain,
  BushChain,
  ExtendedPitchChain,
  FeederChain,
  ElevatorChain,
  Other1,
  Other2,
  Other3,
  Other4,
  Other5,
  Other6,
  Other7,
  Other8,
  Other9,
  Other10,
  Other11,
  Other12,
  Other13,
  Other14,
  Other15,
  Other16,
  Other17,
  RollerChainSimplex,
  RollerChainSimplexGraph,
  RollerChainDuplexImage,
  RollerChainDuplexGraph,
  RollerChainTriplex,
  RollerChainTriplexGraph,
  LeafChain,
  LeafChainGraph,
  ExtendedPitchChainImage,
  ExtendedPitchChainGraph,
  BushChainImage,
  BushChainGraph,
  AgricultureFeederChain,
  AgricultureFeederChainGraph,
  AgricultureElevatorChain,
  AgricultureElevatorChainGraph,

  ind1,
  ind2,
  ind3,
  ind4,
  ind5,
  ind6,
  ind7,
  ind8,
  ind9,
  ind10,
  ind11,
  ind12,
};
